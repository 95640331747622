<template>
  <div class="journey-wrapper">
    <div class="hamburger" v-if="!sidebarOpen">
      <span class="material-icons" @click="sidebarOpen = !sidebarOpen">
        menu
      </span>
    </div>
    <aside class="sidebar" :class="{ open: sidebarOpen }">
      <span class="material-icons close" @click="sidebarOpen = false">
        close
      </span>
      <div class="tech-wrapper p-t-25">
        <div class="ui divider m-t-10 m-b-10"></div>
        <!-- Sign Out -->
        <a class="link" href @click.prevent="signOutClickHandler">
          <i class="icon material-icons">exit_to_app</i>
          <span>Sign Out</span>
        </a>
      </div>
    </aside>
    <router-view v-if="!sidebarOpen"></router-view>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { logoutRoutine } from '@/store/modules/auth/routines'
import { companyGetters, companySelectRoutine } from '@/store/modules/company/routines'

export default {
  name: 'Journey',
  data() {
    return {
      sidebarOpen: false
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      currentCompanyId: companyGetters.COMPANY_CURRENT_ID
    })
  },
  methods: {
    ...mapActions({
      logout: logoutRoutine.TRIGGER,
      selectCompany: companySelectRoutine.TRIGGER
    }),
    backToClientsClickHandler(route) {
      this.selectCompany(null)
      this.$router.push(route)
    },
    signOutClickHandler() {
      this.logout()
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/swoop/_variables';

.journey-wrapper {
  display: flex;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }

  .hamburger {
    display: none;
    cursor: pointer;

    @media only screen and (max-width: 800px) {
      padding: 0.5rem 1rem;
      display: flex;
    }
  }

  aside.sidebar {
    width: 200px;
    height: 100%;
    display: flex;
    padding: 12px;
    flex-direction: column;

    @media only screen and (max-width: 800px) {
      display: none;

      &.open {
        display: flex;
        width: 100%;
        position: absolute;
        top: 0;
      }
    }

    .close {
      cursor: pointer;
      @media only screen and (min-width: 800px) {
        display: none;
      }
    }

    .tech-wrapper {
      position: fixed;
      bottom: 0;
      margin-bottom: 24px;
      $icon-size: 24px;
      .link {
        display: flex;
        align-items: center;
        height: 34px;
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        color: $color-main;
        text-decoration: none;
        &:hover,
        &:active,
        &.active,
        &.router-link-exact-active {
          color: $color-link;
        }
        .icon {
          width: $icon-size;
          height: $icon-size;
          margin-right: 12px;
          color: var(--color-primary-300);
        }
      }
    }
  }
  .ui.stackable.grid {
    height: 100%;
    background-color: #ffffff;
    width: 100%;
    .container {
      padding: 1rem;
      @media only screen and (min-width: 770px) {
        margin-top: 64px;
        h2.title {
          margin-top: 64px;
        }
      }
      &.help {
        margin: 0 auto;
        padding: 1rem;
        height: 100%;
        display: flex;
        .content {
          width: 200px;
          margin-top: 148px;
        }
      }
      .form {
        .ui.button {
          margin-right: 1rem;
          min-width: 96px;
        }
      }
    }
  }

  .ui.segment {
    margin: 0 auto;
  }

  .ui.content.segment {
    margin-top: 3rem;
    border: none;
  }

  aside {
    @media only screen and (max-width: 770px) {
      display: none;
    }
  }
  @media only screen and (max-width: 770px) {
    [class*='mobile hidden'],
    [class*='tablet only']:not(.mobile),
    [class*='computer only']:not(.mobile),
    [class*='large screen only']:not(.mobile),
    [class*='widescreen only']:not(.mobile),
    [class*='or lower hidden'],
    .ui.stackable.grid {
      .container {
        .ui.button {
          width: 112px;
          margin-bottom: 1rem;
        }
        &.help {
          margin: 0 auto;
          justify-content: center;
          .content {
            text-align: center;
            margin-top: auto;
            width: 100%;
          }
          .icon {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
</style>
